@each $i in 0, 5, 10, 20, 30, 40, 60, 80, 100 {
	.pt#{$i} { padding-top: #{$i}px !important; }
	.pr#{$i} { padding-right: #{$i}px !important; }
	.pb#{$i} { padding-bottom: #{$i}px !important; }
	.pl#{$i} { padding-left: #{$i}px !important; }
	.p#{$i} { padding: #{$i}px !important; }

	.mt#{$i} { margin-top: #{$i}px !important; }
	.mr#{$i} { margin-right: #{$i}px !important; }
	.mb#{$i} { margin-bottom: #{$i}px !important; }
	.ml#{$i} { margin-left: #{$i}px !important; }
	.m#{$i} { margin: #{$i}px !important; }
}