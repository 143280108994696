.flash-message {
  	padding: 10px;
  	margin-bottom: 20px;
}

.flash-message-info {
	background: #e3e3e3;
}

.flash-message-good {
	background: #8AC48A;
}

.flash-message-bad {
	background: red;
	color: white;
}